import React, { useEffect, useRef } from "react";
import { useFrame } from "@react-three/fiber";
import { useGLTF, useScroll, useTexture, SpotLight } from "@react-three/drei";
import { useCurrentSheet } from "@theatre/r3f"
import { val } from "@theatre/core";
import { throttle } from 'lodash';
import { MeshReflectorMaterial } from "@react-three/drei";
import * as THREE from 'three';

export function Model(props) {

    const sheet = useCurrentSheet();
    const scroll = useScroll();

    // our callback will run on every animation frame
    useFrame(() => {
        // the length of our sequence
        const sequenceLength = val(sheet.sequence.pointer.length);
        sheet.sequence.position = scroll.offset * sequenceLength;
    });

    useEffect(() => {
        

        window.addEventListener('wheel', () => {
            if (scroll.offset >= 0 && scroll.offset < 0.19) {
                document.querySelector('#DIV1').classList.add('section1-active')
                document.querySelector('#DIV2').classList.remove('section2-active')
                document.querySelector('#DIV3').classList.remove('section3-active')
                document.querySelector('#DIV4').classList.remove('section4-active')
            }

            else  if (scroll.offset >= 0.2 && scroll.offset < 0.35) {
                document.querySelector('#DIV1').classList.remove('section1-active')
                document.querySelector('#DIV2').classList.add('section2-active')
                document.querySelector('#DIV3').classList.remove('section3-active')
                document.querySelector('#DIV4').classList.remove('section4-active')
            }

            else if (scroll.offset >= 0.36 && scroll.offset < 0.55) {
                document.querySelector('#DIV1').classList.remove('section1-active')
                document.querySelector('#DIV2').classList.remove('section2-active')
                document.querySelector('#DIV3').classList.add('section3-active')
                document.querySelector('#DIV4').classList.remove('section4-active')
            }

            else if (scroll.offset >= 0.56 && scroll.offset < 0.8) {
                document.querySelector('#DIV1').classList.remove('section1-active')
                document.querySelector('#DIV2').classList.remove('section2-active')
                document.querySelector('#DIV3').classList.remove('section3-active')
                document.querySelector('#DIV4').classList.add('section4-active')
            }
        })

        window.addEventListener('touchmove', () => {
            console.log(scroll.offset)
            console.log("click")
            if (scroll.offset === 0) {
                document.querySelector('#DIV1').classList.add('section1-active')
                document.querySelector('#DIV2').classList.remove('section2-active')
                document.querySelector('#DIV3').classList.remove('section3-active')
                document.querySelector('#DIV4').classList.remove('section4-active')
            }

            if (scroll.offset > 0.2 && scroll.offset < 0.25) {
                document.querySelector('#DIV1').classList.remove('section1-active')
                document.querySelector('#DIV2').classList.add('section2-active')
                document.querySelector('#DIV3').classList.remove('section3-active')
                document.querySelector('#DIV4').classList.remove('section4-active')
            }

            if (scroll.offset > 0.45 && scroll.offset < 0.5) {
                document.querySelector('#DIV1').classList.remove('section1-active')
                document.querySelector('#DIV2').classList.remove('section2-active')
                document.querySelector('#DIV3').classList.add('section3-active')
                document.querySelector('#DIV4').classList.remove('section4-active')
            }

            if (scroll.offset > 0.75 && scroll.offset < 0.8) {
                document.querySelector('#DIV1').classList.remove('section1-active')
                document.querySelector('#DIV2').classList.remove('section2-active')
                document.querySelector('#DIV3').classList.remove('section3-active')
                document.querySelector('#DIV4').classList.add('section4-active')
            }
        })
    }, []);

    const model = useGLTF("/__3rd-grade-mall.glb");

    const banners = useRef([]);

    // Callback function to handle the traverse operation
    const traverse = (node) => {
        if (node.isMesh && (node.name === "banner1" || node.name === "banner2")) {
            banners.current.push(node);
        }
    };

    // Start the traversal when the model is loaded
    model.scene.traverse(traverse);

    // Load textures and apply them to the banners
    const banner1Texture = useTexture("/img/ichingmaster.png", (texture) => {
        texture.flipY = false;
    });
    const banner2Texture = useTexture("/img/ichingmaster2.png", (texture) => {
        texture.flipY = false;
    });

    banners.current.forEach((banner) => {
        if (banner.name === "banner1") {
            banner.material.map = banner1Texture;
        } else if (banner.name === "banner2") {
            banner.material.map = banner2Texture;
        }
    });

    const object = new THREE.Object3D()
    object.position.set(0, 5, 3.5)

    return (
        <>
            <group {...props} dispose={null}>
                <mesh>
                    <primitive object={model.scene} scale={1100} />
                </mesh>
                <mesh position={[0, -4, 0]} rotation={[-Math.PI / 2, 0, 0]}>
                    <planeGeometry args={[1800, 1800]} />
                    <MeshReflectorMaterial
                        blur={[0, 0]}
                        resolution={2048}
                        mixBlur={0}
                        mixStrength={1}
                        roughness={1}
                        depthScale={1}
                        minDepthThreshold={0.4}
                        maxDepthThreshold={1.4}
                        color="#212121"
                        metalness={0.5}
                    />
                </mesh>
            </group>
            <SpotLight
                castShadow
                penumbra={1}
                position={[3, -1, 0]}
                distance={6}
                angle={0.08}
                attenuation={5}
                anglePower={7}
                intensity={50}
                power={15}
                target={object}
                
            />
            <SpotLight
                castShadow
                penumbra={1}
                position={[-3, -1, 0]}
                distance={6}
                angle={0.08}
                attenuation={5}
                anglePower={7}
                intensity={50}
                power={15}
                target={object}
            />
            <SpotLight
                castShadow
                penumbra={1}
                position={[0, -1, -3]}
                distance={6}
                angle={0.08}
                attenuation={5}
                anglePower={7}
                intensity={50}
                power={15}
                target={object}
            />
            <SpotLight
                castShadow
                penumbra={1}
                position={[2, -1, 2]}
                distance={6}
                angle={0.08}
                attenuation={5}
                anglePower={7}
                intensity={50}
                power={15}
                target={object}
            />
            <SpotLight
                castShadow
                penumbra={1}
                position={[-2, -1, 2]}
                distance={6}
                angle={0.08}
                attenuation={5}
                anglePower={7}
                intensity={50}
                power={15}
                target={object}
            />
        </>
    );
}

useGLTF.preload("/__3rd-grade-mall.glb");