import { createBrowserRouter } from 'react-router-dom';
import App from '../App';
import Faq from '../components/Faq';
import Tos from '../components/Tos';
import Statement from '../components/Statement';
import Contact from '../components/Contact';

const routes = createBrowserRouter([
    {
        path: '/',
        element: <App />,
    },
    {
        path: '/faq',
        element: <Faq />,
    },
    {
        path: '/tos',
        element: <Tos />,
    },
    {
        path: '/statement',
        element: <Statement />,
    },
    {
        path: '/contact',
        element: <Contact />,
    }
]);

export default routes;