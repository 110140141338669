import React from 'react';
import { Link } from 'react-router-dom';


export default function Overlay() {
  
    return (
        <>
        <div>
            <div className="navmenu" id="navmenu">
                <div className="logo">
                    <a href="#">李居明大師網上影院</a>
                </div>
                <ul>
                    <li className="buttons">
                        <select name="language">
                            <option value="繁體">繁體</option>
                            <option value="簡體">簡體</option>
                        </select>
                        <button type="button">Log In</button>
                    </li>
                </ul>
            </div>
            <div className="blocks section-1 headline1" id="DIV1">
                <div>
                    <div className="headings">
                    歡迎來到李居明大師網上影院
                    </div>
                    <div className="Content">
                        <p>開始瀏覽</p>
                        <p>1 ​請點擊選擇目的地
                            <ul>
                                <li>​《大迷信》電影館</li>
                                <li>粵劇電影館</li>
                                <li>佛歌音樂廳</li>
                                <li> 大師影像館</li>
                                <li>售票處   禮品部</li>
                            </ul>
                        </p>
                        <p>2 ​選擇付款方式 </p>
                        <p> 3 ​輸入電郵地址及建立密碼 </p>
                        <p> 4 ​開始觀賞 </p>
                    </div>
                </div>
            </div>

            <div className="blocks section-2 video_section" id="DIV2">
                <div>
                    <div className="headings">
                    歡迎來到《大迷信》電影館
                    </div>
                    <div className="Content">
                    在國際享負盛名的風水術數名師李居明大師，被譽為古文化應用大師。自香港浸會大學傳理系畢業後，曾任職報界及電影圈多年，於80年代中期全身投入命理術數工作，雖為玄學名家，但處處追求「說迷信破迷信」之科學精神。由2017起，李大師成立「智富電影文化國際公司」，致力製作與中華古文化一脈相通的題材，包括玄學檔案紀錄片《大迷信3潮爆開運王》、《豬年運程》、《通靈師》、《大剃度》、《致富者聯盟》、《粵劇靈》、《大運同行》、《易經風雲錄》、《大覺醒》等，開創不一樣的片種，以全新電影語言演繹李居明玄學電影，在海外多個影展中獲獎無數，令人看到香港電影另一股潛在力量
                    </div>
                    <div className="video_area">
                        <iframe width="100%" height="auto" src="https://www.youtube.com/embed/A4r8NSj0-KA"></iframe>
                    </div>
                </div>
            </div>

            <div className="blocks section-3 " id="DIV3">
                <div className="cinemaitem_area">
                <div className="cinema_content">
                    <div className="headings">
                    《大迷信》電影館即日上映電影
                    </div>
                    <div className="Content">
                        <ul>
                            <li><p>易經風雲錄:</p> <p> 易經》對今日世局早有預言，大師不單大膽揭示，更教人處世行運秘法 </p></li>
                            <li><p>粵劇靈:</p> <p>這是大師影像版的梨園日記，記載香港粵劇界風雲人物的軼事，靈性又感性</p></li>
                            <li><p>大剃度:</p> <p>41位從香港出發到日本高野山雪地出家的年輕人，從接觸到修持密法，各有感人故事</p></li>
                            <li><p>大運同行:</p> <p>九運已至，想知如何在九運裡行好運，本片一定不能錯過</p></li>
                            <li><p>致富者聯盟:</p> <p>李居明大師化身致富者聯盟隊長，帶領一眾紅人明星到世界各地探尋發達捷徑</p></li>
                            <li><p>通靈師: </p> <p>日本、台灣、中國、美國多位著名通靈師逐一亮相兼比拼，不嚇人，但驚人！</p></li>
                            <li><p>豬年運程: </p><p>大師為紀念運程書出版30周年而特別攝製之作，以影像解構各生肖運程及行運秘技</p></li>
                            <li><p>潮爆開運王: </p><p>大師率眾星12天日本探奇述異，揭開日本不為人知的靈異故事，新奇有趣</p></li>
                        </ul>
                    </div>
                </div>
                <div className="cinema_list">
                    <a href="#" className="cinema_item">
                    <video
                        id="my-player"
                        class="video-js"
                        controls
                        preload="auto"
                        poster="./img/ichingmaster.png"
                        data-setup='{}'>
                        <source src="//vjs.zencdn.net/v/oceans.mp4" type="video/mp4"></source>
                        <source src="//vjs.zencdn.net/v/oceans.webm" type="video/webm"></source>
                        <source src="//vjs.zencdn.net/v/oceans.ogv" type="video/ogg"></source>
                    </video>
                        {/* <img src="./img/ichingmaster.png"></img> */}
                        <h3>易經風雲錄</h3>
                        <p>$100.00</p>
                    </a>
                    <a href="#" className="cinema_item">
                        <video
                            id="my-player"
                            class="video-js"
                            controls
                            preload="auto"
                            poster="./img/ichingmaster3.png"
                            data-setup='{}'>
                            <source src="//vjs.zencdn.net/v/oceans.mp4" type="video/mp4"></source>
                            <source src="//vjs.zencdn.net/v/oceans.webm" type="video/webm"></source>
                            <source src="//vjs.zencdn.net/v/oceans.ogv" type="video/ogg"></source>
                        </video>
                        {/* <img src="./img/ichingmaster3.png"></img> */}
                        <h3>大剃度</h3>
                        <p>$100.00</p>
                    </a>
                    <a href="#" className="cinema_item">
                        <video
                            id="my-player"
                            class="video-js"
                            controls
                            preload="auto"
                            poster="./img/ichingmaster4.png"
                            data-setup='{}'>
                            <source src="//vjs.zencdn.net/v/oceans.mp4" type="video/mp4"></source>
                            <source src="//vjs.zencdn.net/v/oceans.webm" type="video/webm"></source>
                            <source src="//vjs.zencdn.net/v/oceans.ogv" type="video/ogg"></source>
                        </video>
                        {/* <img src="./img/ichingmaster4.png"></img> */}
                        <h3>大運同行</h3>
                        <p>$100.00</p>
                    </a>
                    <a href="#" className="cinema_item">
                        <video
                            id="my-player"
                            class="video-js"
                            controls
                            preload="auto"
                            poster="./img/ichingmaster5.png"
                            data-setup='{}'>
                            <source src="//vjs.zencdn.net/v/oceans.mp4" type="video/mp4"></source>
                            <source src="//vjs.zencdn.net/v/oceans.webm" type="video/webm"></source>
                            <source src="//vjs.zencdn.net/v/oceans.ogv" type="video/ogg"></source>
                        </video>
                        {/* <img src="./img/ichingmaster5.png"></img> */}
                        <h3>通靈師</h3>
                        <p>$100.00</p>
                    </a>
                    <a href="#" className="cinema_item">
                        <video
                            id="my-player"
                            class="video-js"
                            controls
                            preload="auto"
                            poster="./img/ichingmaster6.png"
                            data-setup='{}'>
                            <source src="//vjs.zencdn.net/v/oceans.mp4" type="video/mp4"></source>
                            <source src="//vjs.zencdn.net/v/oceans.webm" type="video/webm"></source>
                            <source src="//vjs.zencdn.net/v/oceans.ogv" type="video/ogg"></source>
                        </video>
                        {/* <img src="./img/ichingmaster6.png"></img> */}
                        <h3>致富者聯盟</h3>
                        <p>$100.00</p>
                    </a>
                    <a href="#" className="cinema_item">
                        <video
                            id="my-player"
                            class="video-js"
                            controls
                            preload="auto"
                            poster="./img/ichingmaster7.png"
                            data-setup='{}'>
                            <source src="//vjs.zencdn.net/v/oceans.mp4" type="video/mp4"></source>
                            <source src="//vjs.zencdn.net/v/oceans.webm" type="video/webm"></source>
                            <source src="//vjs.zencdn.net/v/oceans.ogv" type="video/ogg"></source>
                        </video>
                        {/* <img src="./img/ichingmaster7.png"></img> */}
                        <h3>大迷信之3潮爆開運王</h3>
                        <p>$100.00</p>
                    </a>
                    <a href="#" className="cinema_item">
                        <video
                            id="my-player"
                            class="video-js"
                            controls
                            preload="auto"
                            poster="./img/ichingmaster8.png"
                            data-setup='{}'>
                            <source src="//vjs.zencdn.net/v/oceans.mp4" type="video/mp4"></source>
                            <source src="//vjs.zencdn.net/v/oceans.webm" type="video/webm"></source>
                            <source src="//vjs.zencdn.net/v/oceans.ogv" type="video/ogg"></source>
                        </video>
                        {/* <img src="./img/ichingmaster8.png"></img> */}
                        <h3>豬年運程</h3>
                        <p>$100.00</p>
                    </a>
                    <a href="#" className="cinema_item">
                        <video
                            id="my-player"
                            class="video-js"
                            controls
                            preload="auto"
                            poster="./img/ichingmaster2.png"
                            data-setup='{}'>
                            <source src="//vjs.zencdn.net/v/oceans.mp4" type="video/mp4"></source>
                            <source src="//vjs.zencdn.net/v/oceans.webm" type="video/webm"></source>
                            <source src="//vjs.zencdn.net/v/oceans.ogv" type="video/ogg"></source>
                        </video>
                        {/* <img src="./img/ichingmaster2.png"></img> */}
                        <h3>粵劇靈</h3>
                        <p>敬請期待</p>
                    </a>
                </div>
                </div>
            </div>

            <div className="blocks section-4 video_section" id="DIV4">
                <div>
                    <div className="headings">
                    粵劇電影館
                    </div>
                    <div className="Content">
                    在國際享負盛名的風水術數名師李居明大師，被譽為古文化應用大師。自香港浸會大學傳理系畢業後，曾任職報界及電影圈多年，於80年代中期全身投入命理術數工作，雖為玄學名家，但處處追求「說迷信破迷信」之科學精神。由2017起，李大師成立「智富電影文化國際公司」，致力製作與中華古文化一脈相通的題材，包括玄學檔案紀錄片《大迷信3潮爆開運王》、《豬年運程》、《通靈師》、《大剃度》、《致富者聯盟》、《粵劇靈》、《大運同行》、《易經風雲錄》、《大覺醒》等，開創不一樣的片種，以全新電影語言演繹李居明玄學電影，在海外多個影展中獲獎無數，令人看到香港電影另一股潛在力量
                    </div>
                    <div className="video_area">
                        <iframe width="100%" height="auto" src="https://www.youtube.com/embed/AdnkiloA0QE"></iframe>
                    </div>
                </div>
            </div>
            <footer>
                <ul>
                    <li>
                    <Link to="/faq">常見問題</Link>
                        {/* <a href="/about">常見問題</a> */}
                    </li>
                    <li>
                    <Link to="/tos">服務條款</Link>
                        {/* <a href="#">服務條款</a> */}
                    </li>
                    <li>
                    <Link to="/statement">聲明</Link>
                        {/* <a href="#">聲明</a> */}
                    </li>
                    <li>
                    <Link to="/contact">聲明</Link>
                        {/* <a href="/contact">聯絡我們</a> */}
                    </li>
                </ul>
            </footer>
        </div>
        </>
    )
}