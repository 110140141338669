import React, { Suspense } from 'react'
import "@fontsource/bebas-neue";
import './style.css'
import ReactDOM from 'react-dom/client'
import Preloader from './components/preloader'
import Endpoint from './endpoint'
import App from "./App";
import { RouterProvider } from 'react-router-dom';
import routes from './routes/routes';

ReactDOM.createRoot(document.getElementById('root')).render(
    < React.StrictMode >
        <Suspense fallback={<Preloader />}>
            <RouterProvider router={routes} />
        </Suspense>
    </React.StrictMode >
)