import React from "react";
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Canvas } from "@react-three/fiber";
import { PerspectiveCamera, ScrollControls, Environment } from "@react-three/drei";
import { Model } from "./components/Model";
import Overlay from "./components/overlay";
import About from './components/Faq';
import { getProject } from '@theatre/core';
import { editable as e, SheetProvider } from '@theatre/r3f';
import Animation from "./Animation.json";

export default function App() {
    const sheet = getProject('Product Animation', { state: Animation }).sheet('Scene');

    return (
        <>
            <Overlay />  
            <Canvas className="canvas">
                <ambientLight intensity={1} />
                <Environment preset="city" />
                <PerspectiveCamera fov={55} />
                <ScrollControls pages={8} damping={0.4}>
                    <SheetProvider sheet={sheet}>
                        <e.mesh theatreKey="House">
                            <Model scale={0.08} position={[0, -0.7, 0]} />
                        </e.mesh>
                    </SheetProvider>
                </ScrollControls>
            </Canvas>
        </>
    );
}