import React from 'react';
import { Link } from 'react-router-dom';

function Tos() {
  return (
    <>
    <div>
      <div className="navmenu" id="navmenu">
        <div className="logo">
            <Link to="/">李居明大師網上影院</Link>
            {/* <a href="#">李居明大師網上影院</a> */}
        </div>
        <ul>
            <li className="buttons">
                <select name="language">
                    <option value="繁體">繁體</option>
                    <option value="簡體">簡體</option>
                </select>
                <button type="button">Log In</button>
            </li>
        </ul>
      </div>

      <div className='section_area'>
        <h2 className='section_title'>服務條款 </h2>

        <div className='section_content'>
          <p>歡迎來到《李居明大師網上影院》(本影院)及使用我們的服務。本影院為閣下提供服務時受以下的服務條款(本服務條款)限制；本服務條款有可能不時被本影院更新而不需另行通知，歡迎不時瀏覽本服務條款的更新版本。此外，當使用某些本影院及連結至李居明多媒體購物平台所擁有或經營的服務時，您及本影院雙方均需要遵守為特定服務而不時公開張貼的指引及規則所限制；所有該等指引或規則於此經提述已被包涵在本服務條款內。本影院亦可能提供其他受不同或附加服務條款限制的服務。</p>

          <div className='tos_item'>
            <h4>1 服務說明</h4>
            <p>本影院透過本影院網路為使用者提供豐富的線上資源（「本服務」），用戶可透過各類型的媒體或已知的或將來研發的裝置進入本影院網路。您明白及同意本服務可能包括廣告，而這些廣告是本影院為提供本服務所必須的。您亦明白及同意本服務可能包括本影院發出的一些通訊，例如服務公告，行政資訊及本影院通訊，而這些通訊是本影院服務的一部份，您並不能選擇停止接收。除非另有其他明文規定，增加或強化目前本服務的任何新功能 (包括所推出的新產品)均受到本服務條款之規範。您瞭解也同意，本服務是依現況提供，任何使用者通訊或個人化設定之時效、刪除、傳遞錯誤、和未予儲存，本影院均不予負責。本影院保留權利毋須事先通知閣下，暫停全部或部分服務以進行維修、保養、更新或其他本影院認為適當的工作。閣下必須自行取得本服務，而與此有關的協力廠商費用(例如互聯網供應商或按時收費)，包括與廣告展示或傳送有關之費用，您亦需負責。此外，您必須提供取得服務所需之一切必要裝備及為此負責。請留意本服務的一些部份可能包括成人內容，您必須年滿18歲才可瀏覽這些部份。用戶必須至少年滿 18 歲或達到您所在省份、地區或國家的法定成年年齡，方可成為服務的用戶。未成年人須在成年人監督下使用本服務。您同意不會把本服務的任何部分(包括您的本影院帳號)，本服務的使用或登陸本服務複製、保留副本、抄襲、出售、買賣、轉賣或利用作為任何商業用途。閣下同意，本服務條款另有明確規定的除外，本協議將不存在任何協力廠商的權益人。</p>
            <p>您同意不使用此服務進行公開播放。本影院各項內容顯示的畫質可能因裝置不同而有差異，而且可能受到多種因素的影響，例如您所在的位置、可用頻寬及/或網路連線速度。可否以高畫質 (HD)、超高畫質 (Ultra HD) 或高動態範圍影像 (HDR) 播放受到網路服務與裝置本身性能的限制。並非所有內容均能以所有格式播放（例如高畫質、超高畫質或高動態範圍影像），而且並非所有本影院的內容均提供所有格式。串流播放高畫質影片（HD，即 720p 或更高解析度）所需的最低連線速度為3.0 Mbps，但我們建議使用更高的連線速度來取得更佳畫質。串流播放全高畫質（Full HD，即 1080p 或更高解析度）內容時，建議使用至少5.0 Mbps 的連線速度。串流播放超高畫質（Ultra HD，即 4K 或更高解析度）內容時，建議使用至少15.0 Mbps 的連線速度。 所有網路連線費用由您自行承擔。請諮詢您的網路提供者，瞭解可能適用的網路數據費用資訊。開始觀賞本影院內容所需花費的時間受到多重因素的影響，包括您所在位置、當時可用的頻寬、您所選取的內容及相容裝置的配置。</p>
          </div>
          <div className='tos_item'>
            <h4>2 用戶註冊義務</h4>
            <p>為了能使用本服務，您同意以下事項：</p>
            <p>(a)依本服務註冊表之提示提供您本人正確、精確、最新及完整的資料（前開資料以下簡稱「登記資料」）</p>
            <p>(b)維持並立即更新「登記資料」，確保其為正確、精確、最新及完整。若您提供任何錯誤、不實、過時、不完整或有誤導成分的資料，或者本影院有合理的理由懷疑前開資料為錯誤、不實、過時、不完整或有誤導成分的資料，本影院有權暫停或終止您的帳號，並拒絕您於現在和未來使用本服務之全部或一部份。本影院十分關切所有使用者（特別是兒童）的安全及私隱。</p>
          </div>
          <div className='tos_item'>
            <h4>3  私隱政策及個人資料收集聲明</h4>
            <p>本影院非常重視您的個人資料和私隱。 由本影院保存並與您有關的“登記資料”及其他相關資料均受到個人資料條例和本公司“私隱權政策”之規管。歡迎隨時到瀏覽以下的私隱權政策全文。
            </p>
          </div>
          
          <div className='tos_item'>
            <h4>我們的承諾</h4>
            <p>承諾尊重閣下個人私隱，維護閣下個人私隱得到保密，並嚴格遵守香港特別行政區法例第486章《個人資料（私隱）條例》的規定，且同時訂立本政策，目的為閣下闡明本影院如何收集、使用、披露及保留閣下的個人資料。</p>
          </div>
          <div className='tos_item'>
            <h4>收集個人資料</h4>
            <p>我們收集個人資料的類型將取決於您與我們交易的性質。我們可能會收集您的個人資料:</p>
            <p>a 當您使用本網站登記註冊成為我們用戶;</p>
            <p>b 相冊許可權只會在您選擇上傳圖片作查詢問題時使用;</p>
            <p>c 當您登記註冊使用、或輸入資訊到任何我們的互動服務;</p>
            <p>d 當您使用本網站報名參加我們的任何活動;</p>
            <p>e 當您使用本網站向我們選購商品和服務，及；</p>
            <p>f 當您使用本網站與我們聯絡。</p>
            <p>我們可能自己或透過任何我們的隸屬、聯營、相關、附屬公司收集您的個人資料，包括：直接由您;當您瀏覽由公司經營的其他網站，使用由我們提供的服務或產品，或從我們公司購物; 及可獲得的商業資料來源。</p>
            <p>除了任何其他詳情之外，我們還可能會收集：</p>
            <p>a	我們會訪問您的相冊許可權：用於問答功能上，您可主動附加圖片。您亦可在上傳圖片提交線下電影戲票兌換禮物</p>
            <p>b	戶口登記資料，包括您的姓名、稱號、聯絡號碼（流動電話）、郵政位址和電郵地址、出生日期;</p>
            <p>c	付款資料、交易和信用卡資料（如果您從我們購票/購物）;</p>
            <p>d	郵寄資料（如果您從我們購物）;</p>
            <p>e	您和我們之間的信函記錄；及</p>
            <p>f	有關您個人或職業興趣、人口統計資料、對我們產品和服務的經驗及偏好的聯繫方式，以便向您提供我們產品和服務的進一步資訊。</p>
            <p>所有由我們收集關於您的資料，及由公司收集關於您的資料，可能會與由我們或任何公司所持有關於您的其他資料合併。</p>
            <p>請注意，在我們網站提供的大多數商品和服務、資訊，市場行銷和宣傳材料的對象不是未滿18歲的青年。如果您未滿18歲，您必須先取得您的父母或監護人的同意，才可向我們提供任何您的個人資料。</p>
          </div>
          <div className='tos_item'>
            <h4>個人資料的使用</h4>
            <p>我們收集您的個人資料，以便我們能夠提供我們的產品和服務，並經營業務。例如：</p>
            <p>a		為您提供我們及/或集團公司的各種產品和服務、市場行銷和宣傳材料、活動資訊和網上購物的項目;</p>
            <p>b		以便處理和跟進服務電話、查詢和投訴;</p>
            <p>c 	有關行政通知及有關您使用本網站的通訊而聯絡您及回應您的查詢或要求;</p>
            <p>d 	以協助我們的未來行銷目的及/或與協力廠商聯合推廣活動的發展;</p>
            <p>e		以核實本網站用戶的身份;及</p>
            <p>f 	以獲取有關我們用戶的統計以分析網站的使用率，以幫助其進一步發展。</p>
            <p>雖然您沒有義務提供本網站上要求的資料或資訊，但如果您選擇不提供資料，我們或者不能向您提供我們公司的產品和服務、資訊及市場行銷和宣傳材料。我們不會出售或出租您的個人資料予協力廠商，或與協力廠商就您的個人資料進行交易。在下述情況下，我們可能會披露您的個人資料予其他人士：</p>
            <p>a 	已正式獲得我們授權使用您的個人資料的任何協力廠商產品和服務供應商，其將協助我們的業務發展。我們的協力廠商供應商將受合同的約束，必須按照我們與他們的服務協定的條款，及適用協力廠商供應商營運區域的所有法律及法規的規定使用您的個人資料;</p>
            <p>b 	在我們所從事的業務中代表我們的代理商;</p>
            <p>c 	我們公司，讓您可以獲得我們公司提供的產品和服務資訊，及/或讓我們可在我們各自從事的業務中互相提供產品和服務;</p>
            <p>d 	在我們需要保護和捍衛我們的權利和財產的情況下;</p>
            <p>e 	在我們認為我們必須這樣做，以符合適用的法律和法規; 及</p>
            <p>f 	在我們認為有必要，以維持和改善我們的服務的情況下。</p>
            <p>而您的信用卡帳戶號碼永不會儲存於流動裝置中，因此更能保障您的帳戶資料。</p>
          </div>
          <div className='tos_item'>
            <h4>個人資料的保留及安全</h4>
            <p>保護您被收集的個人資料是我們的一個優先事項。我們會一直努力，以確保您的個人資料將受保護防止未經授權的查閱。我們已經實施了適當的電子和管理措施，以便保障、保護和確保您的個人資料。我們的總部設於香港。您的個人資料可能在香港或世界其他地區被我們查閱或被我們轉移到任何我們以上所提之協力廠商公司及被該協力廠商查閱。您所提供的所有個人資料將會被加密，並轉移至我們的伺服器或我們正式授權協力廠商的伺服器內。該等個人資料只能由我們自己或我們授權的協力廠商人員查閱，而該等人員將被指示在查閱這些個人資料時，必須遵守本私隱聲明的條款。</p>
            <p>雖然我們將竭盡所能，以保護和確保您的個人資料，但我們無法保證您的個人資料，透過互聯網或任何電訊網路傳輸的安全性，而您須自行承擔由您轉移個人資料給我們的任何風險。</p>
          </div>
          <div className='tos_item'>
            <h4>個人資料的查閱或更正</h4>
            <p>您可以隨時要求查閱，並要求我們修改您儲存在我們記錄的個人資料。如您想行使您的權利，或者如果您有任何疑問，請與我們公司營業代表聯絡及查詢。</p>
            <p>如您想查閱或修改您的個人資料，我們可能會要求您提供個人詳細資料，以核實您的身份。我們將盡力在30工作天內回應您的要求，並可能會因此向您收取合理費用。</p>
          </div>
          <div className='tos_item'>
            <h4>4 用戶帳號、密碼及安全</h4>
            <p>完成本服務的登記程式之後，您將收到一個密碼及帳號以方便觀看網上作品。維持密碼及帳號的機密安全，是您的責任。利用該密碼及帳號所進行的一切行動，您將負完全的責任。您同意以下事項：</p>
            <p>(a) 您的密碼或帳號遭到未獲授權的使用，或者其他任何安全問題發生時，您將立即通知本影院，且</p>
            <p>(b) 每次連線完畢，均結束您的帳號使用。您未能遵守本項規定所衍生之任何損失或損害，本影院無法也不予負責。</p>
            
          </div>
          <div className='tos_item'>
            <h4>5 用戶行為</h4>
            <p>您明白所有公開張貼或私下傳送的資料、文字、軟體、音樂、聲音、照片、圖像、視像、訊息、標籤或其他資料(「內容」)均屬內容來源的該人的單獨責任。這表示您，而非本影院，將須為所有您所上載、張貼、發送電郵、傳送之「內容」或以其他方式，通過本服務提供的所有「內容」負全部的責任。本影院無法控制通過本服務所張貼的「內容」，因此並不保證該等「內容」的準確性、完整性或品質。您明白在使用本服務時，您可能接觸到令人反感、不適當、不雅或討厭的「內容」。無論在任何情況下本影院均不會為任何「內容」負上責任，包括但不限於「內容」中的任何錯誤或遺漏，或以及經由本服務上載、張貼、發送電郵，傳送之「內容」或以其他方式通過使用本服務而所導致的損失或傷害。</p>
            <p>您同意不會利用本服務來作以下用途：</p>
            <p>a	上載、張貼、發送電郵、傳送或以其他方式提供任何非法的、有害的、恐嚇性的、辱駡性的、騷擾性的、民事侵權的、破壞名譽的、粗俗的、淫穢的、誹謗的、侵犯他人私隱的、仇恨性的、道德上、在種族、人種等方面或其他方面會引起反感的內容；</p>
            <p>b	在任何方面對未成年人造成傷害；</p>
            <p>c	偽造標頭或用別的方法變換處理器識別工具來隱瞞掩飾通過本服務所傳送之任何內容的來源；</p>
            <p>d	冒充任何人、團體或機構，包括但不限於本影院的職員、論壇領袖、主持人，或以虛偽不實的方式陳述或謊稱與任何人、團體或機構之關係作不真實的聲明或其他虛假陳述；</p>
            <p>e	上載、張貼、發送電郵、傳送或以其他形式提供您在任何法律或合約、或因受託關係您無權提供的資料(例如：因僱傭關係或不披露協議下您所獲得的內幕資料、私有、專屬及機密資料)；</p>
            <p>f	上載、張貼、發送電郵、傳送或以其他形式提供任何侵犯任何方的專利、商標、商業秘密、版權或其他專屬權利（「權利」）之「內容」;</p>
            <p>g	上載、張貼、發送電郵、傳送或以其他形式提供任何未經邀請而主動提供或未獲授權的廣告信函、促銷資料、垃圾郵件、兜售資訊、連鎖信件、層壓式推銷計劃或任何其他形式為爭取客戶的行為，在本影院網路為該等目的而指定的區域(如本影院)除外；</p>
            <p>h	上載、張貼、發送電郵、傳送或以其他形式提供任何帶有電腦病毒或任何其他電腦編碼，檔案或程式的資料用作干擾，破壞或限制任何電腦軟體、硬體或電信設備的功能</p>
            <p>i	干擾對話的正常資訊流程、導致螢幕比本服務的其他使用者能夠輸入的速度更快地移動，或以其他方式令其他使用者進行即時交流的能力受到負面的影響；</p>
            <p>j	故意或非故意妨礙或使本服務、伺服器或與本服務連接的網路中斷，或不遵守與本服務連接的網路的要求，程式、政策或規則；</p>
            <p>k	故意或非故意地違反任何適用的本地、國家或國際法律，包括但不限於任何國家或其他證券交易所的規定及任何具法律效力的規則；</p>
            <p>l	跟蹤、追蹤或以其他方式騷擾其他人；及 /或</p>
            <p>m	就以上(a) - (l) 項所禁止的行為或活動收集或儲存其他使用者的個人資料。</p>
            <p>您明白本影院可能會或可能不會預先審查內容，但本影院和其指定人將有權(但沒有義務)依其自行之單獨判斷預先審查，拒絕或移動任何經由本服務提供之任何內容。在並不限制前述的條款的前提下，本影院和其指定人將有權移除任何違反本服務條款或在其他方面令人反感或厭惡的內容。您同意必須自行評估及承擔所有與使用內容，包括任何倚賴該等內容的準確性，完整性或用處相關的所有風險。就這些方面，您承認您不能倚賴本影院所創製的任何內容，或向本影院呈交的內容，包括但不限在本影院討論區及本服務的所有其他部分的資料。</p>
            <p>您贊成、明白並同意若依據法律或規定的要求，本影院可以進入、保存及披露您的帳戶資料及內容，或當本影院真誠地相信為了以下目的合理地須要進入、保存及披露您的帳戶資料及內容：(a)遵守法律程式；(b)執行本服務條款；(c) 回應任何“內容”侵犯第三者的權利之聲稱；(d) 回應您對客戶服務的請求；或(e)保障本影院、其使用者及公眾的權利、財產或個人安全。 您明白本服務的技術處理程式及本服務的傳送，包括您的內容，可能涉及(a)不同網路之間的傳送，及(b)為了符合及適應所連接的網路或設備裝置之技術要求而作出改變。</p>
            <p>您明白本服務及嵌入本服務的軟體可能包括容許保護數碼材料的防護構成要素，而使用這些材料是受本影院及提供內容給本服務的內容供應商的使用規則所限制。您不能試圖推翻或規避嵌入本服務的使用規則。嚴禁把本服務所提供的資料的部分或全部進行任何未經授權的複製，發表、再分發或公開展示。</p>
            <p>當通過建議或回饋的途徑提交構想、建議、檔案或計劃給本影院時，您承認及同意(a)您的來稿(「來稿」)不包含機密或私有的資料；(b)本影院並不負有義務就「來稿」承擔任何明示或默示的保密義務；(c)本影院將有權以任何方式，在全球任何媒體使用或披露 (或選擇不使用或披露)該等「來稿」，(d) 本影院有可能已經在考慮或在開發與「來稿」類似的東西；(e)您的「來稿」自動成為本影院的財產而本影院並不對您負擔任何的義務；及(f)在任何情況下您不享有任何收取報酬或任何形式的補償的權利。</p>
          </div>
          <div className='tos_item'>
            <h4>6	於本影院張貼之公共資料</h4>
            <p>本影院並不聲稱擁有任何您所提交或提供作為被包括在本服務的“內容”。但是，就您所提交或提供作為被包括在本服務的“公眾可進入區域”的“內容”，您授予本影院以下在全球範圍內，不帶專利及使用費及非專屬性的適用許可；</p>
            <p>就您所提交或提供作為被包括在本影院之「公眾可進入區域」的內容 - 許可本影院在本服務為提供及宣傳某個本影院聯盟而使用，分發、複製、改變、修改、重新整理、翻譯、公開表演及公開展出該等內容，而該等內容是為該個本影院聯盟而提交或提供的。這許可只是在您選擇繼續把該內容包括在本服務內時才存續，而當您移除或是當本影院從本服務移除該內容時終止。</p>
            <p>就本影院聯盟以外其他「公眾可進入區域」的本服務，您所提交及提供除照片、圖像、音象或視象以外的其他內容 - 永久及不可撤銷地許可本影院：(a)使用，分發、複製、改編、修改、重新整理、發表、翻譯、公開表演及公開展出該等內容的全部或部分及把該內容合併到其他任何版式或現存或日後開發的媒體中；及(b)許可其他人作出以上行為。</p>
            <p>本服務的「公眾可進入區域」是在本影院網路中的網站內本影院專為公眾而設的地區。例如，本服務的公眾可進入區域包括本影院對用戶及訪客均開放的本影院 聯盟，相簿及公事包。 </p>
          </div>
          <div className='tos_item'>
            <h4>7 彌償</h4>
            <p>由於您經由本服務提供、張貼或傳送之內容、您使用本服務、您與本服務連線、您違反本服務條款、或您侵害其他人任何權利因而衍生或導致任何第三人提出索賠或請求，包括合理的律師費，您同意賠償本影院及其分公司、分支機搆、主管、代理人、聯名廠商或其他夥伴及員工，並使其免受損害。</p>
          </div>
          <div className='tos_item'>
            <h4>8 服務轉售之禁止</h4>
            <p>本服務任何部分或本服務之使用或存取，您同意不進行重製、拷貝、出售、轉售或作任何商業目的之使用。</p>
          </div>
          <div className='tos_item'>
            <h4>9 服務之修改</h4>
            <p>本影院有權在有發出通知或沒有發出通知的形況下，於任何時間及不時修改、暫停或永遠終止本服務(或其任何部分)。您同意本影院將不會因為任何修改、暫停或終止本服務而向您或第三者負有責任。</p>
          </div>
          <div className='tos_item'>
            <h4>10  終止</h4>
            <p>您同意本影院得基於其自行之考量，因任何理由，包含但不限於缺乏使用，或本影院認為您已經違反本服務條款的明文規定及精神，終止您的密碼、帳號（或其任何部分）或本服務之使用，並將本服務內任何內容加以移除並刪除。本影院無論有否通知您，都可以依其自行之考量隨時終止本服務或其任何部分。您同意依本服務條款任何規定提供之本服務，無需事先通知您即可暫停或終止，您承認並同意，本影院得立即關閉或刪除您的帳號及您帳號中所有相關資料及檔案，及/或禁止前開檔案和本服務之使用。此外，您同意若您被暫停或終止進入服務，或您的帳戶或有關的資料和檔案被凍結或刪除時，本影院對您或任何第三人均不承擔責任。</p>
          </div>
          <div className='tos_item'>
            <h4>11 與廣告商進行之交易</h4>
            <p>您於本服務或經由本服務與廣告商進行通訊或商業往來，或參與促銷活動，包含相關商品或服務之付款及交付，以及前開交易其他任何相關條款、條件、保證或陳述，完全為您與前開廣告商之間之行為。前開任何交易或前開廣告商出現於本服務所生之任何性質的損失或損害，您同意本影院不予負責。</p>
          </div>
          <div className='tos_item'>
            <h4>12  連結</h4>
            <p>本服務或第三人可提供與其他全球資訊網上之網站或資源之連結。由於本影院無法控制前開網站及資源，您瞭解並同意，前開外部網站或資源是否可供利用，本影院不予負責，存在或源於前開網站或資源之任何內容、廣告、產品或其他資料，不代表本影院對其贊同本影院亦不予負責。您進一步同意，因使用或信賴存在或經由前開任何網站或資源之任何內容、商品或服務所生或據稱所生之任何損害或損失，本影院不負任何直接或間接之責任。</p>
          </div>
          <div className='tos_item'>
            <h4>13 本影院之專屬權利</h4>
            <p>您瞭解並同意，本服務及本服務所使用之任何相關必要軟體（以下簡稱軟體）含有受到相關智慧財產權及其他法律保護之專屬性及機密性資料。您也瞭解並同意，經由本服務或廣告商向您呈現之贊助廣告或資訊所包含之內容，受到版權、商標、服務標章、專利或其他專屬權利及法律之保護。未經本影院或廣告商明示授權，您同意不修改、出租、出借、出售、散佈本服務或軟體之一部份或全部，或據以製作衍生著作。本影院授予您個人、不可移轉及非專屬之使用權，使您得於單機電腦使用其軟體之目的碼，惟您不得（並不得允許第三者）重製、修改、創作衍生著作、進行還原工程、反向組譯，或以其他方式發現原始碼，或出售、轉讓、再授權或提供軟體設定擔保，或以其他方式移轉軟體之任何權利。您同意不以任何方式修改軟體，或使用修改後的軟體，包括但不限於為了未經授權而使用本服務之目的。您同意不經由非本影院所提供的介面使用本服務。</p>
          </div>
          <div className='tos_item'>
            <h4>14 免責聲明</h4>
            <p>您明確地明白並同意：</p>
            <p>1.	本影院根據本服務條款履行與服務有關的義務，只限於以合理的技能和謹慎為您提供的相關服務。服務條款並無任何內容，免除或限制本影院因本影院疏忽、欺詐或其他適用法律不能免除或限制的負責任行為，而導致的死亡或人身損害。</p>
            <p>2.	本影院及分支機搆、高級職員、員工、代理人、夥伴及獲受許可方不擔保(i)本服務能滿足及符合您的需要；(ii)本服務將不受干擾、及時提供、安全可靠或不含有錯誤；(iii)使用本服務可以得到的結果將會是準確可靠的；(iv)您通過本服務購買或獲取的任何產品、服務、資訊或其他資料均達到您的預期；及(v)軟體中任何錯誤都將得到更正。</p>
            <p>3.	您自行判斷及承擔當您下載或以其他形式經使用本服務而獲得的任何資料的風險；您自行負責因此而引致對您的電腦系統的損壞或資料的損失。</p>
            <p>4.	任何您從本影院或經或從本服務所獲得的忠告或資訊，無論是口頭或書面的，除在本服務條款中有明確聲明以外，並不構成任何擔保。</p>
            <p>5.	少部分的使用者當使用本服務時，暴露在某些光線圖案或是電腦螢幕會引起癲癇症發作。某些情況會引起以往並無癲癇或發作紀錄的，以前未被發現癲癇病徵的使用者癲癇症發作。假若您或您家庭的任何人士有癲癇症的情況，請在使用本服務前諮詢您的醫生。當您使用本服務時若果您感到以下的病徵請立即停止使用本服務並立即諮詢您的醫生: 頭昏眼花、所看見的事物變樣、眼睛或肌肉抽動痙攣、失去知覺、迷惘、任何不受意志控制的動作或抽搐。</p>
          </div>
          <div className='tos_item'>
            <h4>15 責任限制</h4>
            <p>您明確瞭解並同意，基於以下原因而造成之損失，包括但不限於利潤、商譽、使用、資料損失或其他無形損失，本影院不承擔任何直接、間接、附帶、特別、衍生性或懲罰性賠償（即使本影院已被告知前開賠償之可能性亦然）：(i)本服務之使用或無法使用，(ii)經由或透過本服務購買或取得之任何商品、資料、資訊或服務，或接收之訊息，或進行之交易所衍生之替代商品及服務之購買成本，(iii)您的傳輸或資料遭到未獲授權的存取或變造，(iv)本服務中任何第三人之聲明或行為，或(v)本服務(在此服務條款中以其他方式明確提供的除外)其他相關事宜。</p>
          </div>
          <div className='tos_item'>
            <h4>16 通知</h4>
            <p>向您發出的通知得經由電子郵件和一般郵件為之。本服務條款或其他事項有所變更時，本服務亦可能將展示通知或通知之連結。</p>
          </div>
          <div className='tos_item'>
            <h4></h4>
            <p></p>
          </div>

          <div className='tos_item'>
            <h4>17 商標資訊</h4>
            <p>本影院、本影院標誌，商標及服務標章，以及其他本影院標誌及產品、服務名稱，為本影院公司之商標（以下簡稱本影院標章）。未經本影院事先書面同意，您同意不以任何形式的展示、使用或透過其他方式處置本影院標章，也同意不陳述或聲稱您有權展示、使用或透過其他方式處置本影院標章。</p>
          </div>
          <div className='tos_item'>
            <h4>18  一般條款</h4>
            <p>整份協議。本服務條款構成您與本影院之間的整份協定並規定您對本服務的使用，及取代任何以前您與本影院之間就服務所達成的協議。當您使用或購買某些本影院其他的服務、相關的服務、協力廠商的內容或協力廠商的軟體時您亦可能受到額外適用的條款及條件所限制。</p>
            <p>選擇法律及訴訟地。 本服務條款及您與本影院的關係將受香港法律的管轄(，但並不需要顧及其法律衝突原則)。您與本影院均同意接受香港法院的個人及專屬的司法管轄。</p>
            <p>棄權聲明及條款的可分割性。本影院不行使或予以強制執行任何權利或本服務條款將並不構成放棄該權利或條款。倘若一個具司法能力的法院發現本服務條款本服務協定的任何條款無效，協定各方仍然同意法院應盡力使條款所反映的各方的意向生效，而本服務條款的其他條款將繼續保留完全的效力及效果。</p>
            <p>如果您違反本《使用條款》或以非法或詐欺之途使用服務，則我們可終止或限制您使用我們的服務。
無生存者取得權及非可轉讓性。 您同意您的本影院帳戶是不可以轉讓，以及您的本影院 ID的權利或您帳戶內的任何內容權利於您的死亡時終止。當收到您的身死亡證的副本後，您的帳戶將被終止，所有裡面的內容將被永久刪除。
</p>
            <p>訴訟時效制定法。 您同意不管有任何相反的條例或法律，任何因使用本服務或使用本服務或本服務條款相關的索償或訴因必須在該索償或訴因發生後一(1)年內由您提出，否則將永遠不得提出。 本服務條款本服務協定的章節標題只為提供方便而設，並不具任何法律或契約效力。</p>
          </div>
          <div className='tos_item'>
            <h4>19 違約</h4>
            <p>倘發現任何違反本服務條款之情事，請通知本公司客戶服務部。
如果您違反本《使用條款》或以非法或詐欺之途使用服務，則我們可終止或限制您使用我們的服務。
</p>
          </div>
          <div className='tos_item'>
            <h4>20 法律聲明</h4>
            <p>本影院服務（包含本影院服務提供的所有內容）均受著作權、商標、商業機密或其他智慧財產法律及條約所保障。</p>
          </div>
          <div className='tos_item'>
            <h4>21 著作權</h4>
            <p>本影院服務所提供內容的著作權為李居明先生及智富電影文化國際公司所有。</p>
          </div>

        </div>
      </div>



      <footer>
        <ul>
            <li>
            <Link to="/faq">常見問題</Link>
                {/* <a href="/about">常見問題</a> */}
            </li>
            <li>
            <Link to="/tos">服務條款</Link>
                {/* <a href="#">服務條款</a> */}
            </li>
            <li>
            <Link to="/statement">聲明</Link>
                {/* <a href="#">聲明</a> */}
            </li>
            <li>
            <Link to="/contact">聯絡我們</Link>
                {/* <a href="/contact">聯絡我們</a> */}
            </li>
        </ul>
      </footer>
    </div>
    </>
  )
}

export default Tos;