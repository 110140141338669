// Replace the actual About component with a placeholder
import React from 'react';
import { Link } from 'react-router-dom';

const Faq = () => {
   return (
      <>
      <div>
        <div className="navmenu" id="navmenu">
          <div className="logo">
            <Link to="/">李居明大師網上影院</Link>
              {/* <a href="#">李居明大師網上影院</a> */}
          </div>
          <ul>
              <li className="buttons">
                  <select name="language">
                      <option value="繁體">繁體</option>
                      <option value="簡體">簡體</option>
                  </select>
                  <button type="button">Log In</button>
              </li>
          </ul>
        </div>

         <div className='section_area'>
            <h2 className='section_title'>聲明 </h2>
            <div className='section_content'>
               <div className='faq_item'>
                  <h4>問：	影片可否下載後觀看？</h4>
                  <p>答：	基於版權問題，影片不能以任何形式下載。如有非法下載並轉載，或另作商業用途等，可能會面臨訴訟。觀眾付款後可以暫停、返回及重複觀看，時間為一天內看畢。</p>
               </div>
               <div className='faq_item'>
                  <h4>問：	影片可否中途退票？</h4>
                  <p>答：	如果播放上遇到技術問題，可電郵至cs@masterlicinema.com處理，一般情況下，付款後不能退票。</p>
               </div>
               <div className='faq_item'>
                  <h4>問：	如對片中的資訊有興趣深入了解，可以怎樣聯絡李大師？</h4>
                  <p>答：	可電郵至cs@masterlicinema.com查詢。</p>
               </div>
               <div className='faq_item'>
                  <h4>問：	客戶/訪客經平台輸入的個人資料會如何處理？</h4>
                  <p>答：	我們非常重視您的個人資料和私隱，並承諾尊重閣下個人私隱，維護及保密閣下個人私隱，嚴格遵守香港特別行政區法例第486章《個人資料（私隱）條例》的規定，有關詳細條款，請參閱網頁的私隱條款。</p>
               </div>
            </div>
         </div>

        <footer>
        <ul>
            <li>
            <Link to="/faq">常見問題</Link>
                {/* <a href="/about">常見問題</a> */}
            </li>
            <li>
            <Link to="/tos">服務條款</Link>
                {/* <a href="#">服務條款</a> */}
            </li>
            <li>
            <Link to="/statement">聲明</Link>
                {/* <a href="#">聲明</a> */}
            </li>
            <li>
            <Link to="/contact">聯絡我們</Link>
                {/* <a href="/contact">聯絡我們</a> */}
            </li>
        </ul>
      </footer>
    </div>
    </>
  )
};

export default Faq;