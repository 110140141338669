import React from 'react';
import { Link } from 'react-router-dom';

function Contact() {
  return (
    <>
    <div>
      <div className="navmenu" id="navmenu">
        <div className="logo">
            <Link to="/">李居明大師網上影院</Link>
            {/* <a href="/">李居明大師網上影院</a> */}
        </div>
        <ul>
            <li className="buttons">
                <select name="language">
                    <option value="繁體">繁體</option>
                    <option value="簡體">簡體</option>
                </select>
                <button type="button">Log In</button>
            </li>
        </ul>
      </div>

      <div className='section_area'>
        <h2 className='section_title'>關於/聯絡我們 </h2>
        <div className='section_content'>
          <p><b> 智富電影文化國際公司(G-Full Films Cultural Company) </b> 成立於2017年，由享負盛名的玄學大師李居明創辦，致力以電影傳揚中國古文化瑰寶，包括博大精深的佛教文化、令西方人嘖嘖稱奇的風水文化，以至探奇述異的靈學檔案等等。</p>
          <p>李居明大師在八、九十年代活躍於香港電影界，曾擔任編劇及製片人，其中尤以製作《大迷信》及《大迷信1991》兩齣玄奇紀錄片名揚中外。二十多年後，李大師重返電影界，先後製作《大迷信》系列電影如下：</p>

          <ul>
            <li>-	《大迷信3之潮爆開運王》(2018年製作，全片日本攝製，演出嘉賓有千葉真一、中野良子，櫻木健一、林盛斌、江美儀、河國榮、莊思敏、莊思明等，記述探奇、探鬼、探靈之旅，充滿「驚」喜！)</li>
            <li>-	《大迷信》系列4之《豬年運程》(2019年製作，演出嘉賓有呂良偉、薛家燕、黎耀祥、元秋、江美儀、陳琪、胡諾言、黎芷珊、陳自瑤、梁列唯、莊思敏、范振鋒、李思欣、麥玲玲、李麗珍、何文匯、林寄韻等，是首部以玄學檔案為題的紀錄片，除講述2019豬年期間12生肖的運程外，還提供了大量可以助人行運的玄學資訊。</li>
            <li>-	《大迷信》系列5之《通靈師》 (2020年製作，是一部專以通靈師為題材的紀錄片，內容網羅中國、台灣、日本和美國最負盛名的通靈人訪問，現身說法外還有示範，一新觀眾對靈界的知識和眼界。該片並入圍多個國際影展及榮獲「最佳紀錄片」榮譽，足證外國人對靈學文化的興趣和欣賞。</li>
            <li>-	《大迷信》系列6之《致富者聯盟》(2020年製作，李居明大師親率名人隊員，包括黎芷珊、陳自瑤、魯振順、石修、陳宇琛、莊思敏、石詠莉等組成「致富者聯盟」，飛越歐、亞多個國家，詳細闡述各種能夠幫助觀眾致富的方法。)</li>
            <li>-	《大迷信》系列7之《大運同行》(2021年製作，李居明大師有感三元九運悄然偷步而至，香港風水又暴露種種嚴重問題，因此，他與薛家燕、陳自瑤、胡諾言、敖嘉年、蓋鳴暉、傅嘉莉、江美儀及林寄韻等組成「改運特工隊」，借此風水紀錄片教授未來九運20年的「十大行運攻略」、「9大健身招式」，以至細述香港風水奇談及改運辦法，是不可多得的影像版風水研究文獻。</li>
            <li>-	《大迷信》系列8之《大剃度》(2021年製作，該片是一齣難能可貴的宗教紀錄片，以3 年多的製作時間，記述41位年輕人在大火之上、瀑布之間，以至雪地上苦修密宗的心路歷程，並揭示當年盛傳於唐代的密宗的回歸歷史及其可貴之處。)</li>
            <li>-	《大迷信》系列9之《粵劇靈》(2021年製作，記述李居明大師與粵劇如何結緣，以及戲行內人與靈的精采故事，內容有情有趣、有驚有喜。) </li>
            <li>-	《大迷信》系列10之《易經風雲錄》(2023年製作，深入淺出解釋中國群經之首的《易經》的秘密，並展示應用此文化瑰寶的法門。)</li>
          </ul>

          <div className='mailcontent'>
            <p>電郵：<a href='mailto:gfullfilmsculturalco@gmail.com'>gfullfilmsculturalco@gmail.com</a></p>
            <p>如遇有任何查詢或疑問，請電郵至我們的客服(<a href='mailto:gfullfilmsculturalco@gmail.com'>gfullfilmsculturalco@gmail.com</a>)郵箱，與我們的職員聯絡，我們將盡快回覆。</p>
          </div>
        </div>
        
        <h2 className='section_title'>常見問題 </h2>
        <p>更新中</p>
          <div className='mailcontent'>
            <p>如遇有任何查詢或疑問，請電郵至我們的客服(<a href='mailto:gfullfilmsculturalco@gmail.com'>gfullfilmsculturalco@gmail.com</a>)郵箱，與我們的職員聯絡，我們將盡快回覆。</p>
          </div>
      </div>

      <footer>
        <ul>
            <li>
            <Link to="/faq">常見問題</Link>
                {/* <a href="/about">常見問題</a> */}
            </li>
            <li>
            <Link to="/tos">服務條款</Link>
                {/* <a href="#">服務條款</a> */}
            </li>
            <li>
            <Link to="/statement">聲明</Link>
                {/* <a href="#">聲明</a> */}
            </li>
            <li>
            <Link to="/contact">聯絡我們</Link>
                {/* <a href="/contact">聯絡我們</a> */}
            </li>
        </ul>
      </footer>
    </div>
    </>
  )
}

export default Contact;