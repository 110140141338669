import React from 'react';
import { Link } from 'react-router-dom';

function Statement() {
  return (
    <>
    <div>
      <div className="navmenu" id="navmenu">
        <div className="logo">
            <Link to="/">李居明大師網上影院</Link>
            {/* <a href="#">李居明大師網上影院</a> */}
        </div>
        <ul>
            <li className="buttons">
                <select name="language">
                    <option value="繁體">繁體</option>
                    <option value="簡體">簡體</option>
                </select>
                <button type="button">Log In</button>
            </li>
        </ul>
      </div>


      <div className='section_area'>
        <h2 className='section_title'>聲明 </h2>
        <div className='section_content'>
          <div className='tos_item'>
            <h4>免責聲明</h4>
            <p>本網站所載之資訊（包括但不限於產品及服務資料、圖像、廣告等；統稱「本網站資訊」） 均為本影院依據其現行慣例及政策，並利用其現時所得的資料編製而成。為確保本網站資訊的準確性，本影院將定期進行檢查，並於適當時更新本網站資訊，以反映情況的轉變。</p>
            <p>於任何情況下，本影院概不會就閣下依賴或使用本網站資訊，或因本網站資訊出現任何誤差、誤述、過失、遺漏、干擾、錯誤、欠妥、營運或傳訊延誤，或因本網站系統出現故障而引致的任何直接、間接、附帶、特別、懲罰性或後果性的損害賠償而承擔任何責任。閣下同意向本影院作出彌償並使本影院免責於閣下依賴或使用本網站資訊、所採取的任何行為或行動、侵犯任何協力廠商權利、違反任何法律法規、或違反本聲明任何條款而引起或與之有關的任何申索、責任、損失、損害賠償、支出或費用等。</p>
          </div>
          <div className='tos_item'>
            <h4>版權聲明</h4>
            <p>本網站所載之資訊均受有關版權法例所保護，本影院均保留所有權利，閣下除可列印或下載本網站資訊成硬拷貝件作私人或非商業用途外，概不得以其他方式使用，且不得刪除本網站資訊附載的任何版權聲明。</p>
            <p>閣下欲使用本網站資訊或連結作任何非私人或商業用途前，必須得到本影院書面確認同意後方可使用，否則本影院將對閣下保留一切追究權利。本網站所提及的或本影院同意透過連結方式經本網站進入的任何協力廠商網站，有關網站內容的版權應為該網站擁有者所擁有，閣下欲以任何方式使用該等內容時，應事先諮詢有關網站擁有者。</p>
          </div>
          <div className='tos_item'>
            <h4>使用cookies和其他網站的連結</h4>
            <p>(a) Cookies</p>
            <p>我們不會在您到訪和瀏覽本網站時收集您的任何能識別您個人身份的資料（除非您提供該資料給我們），但我們會使用cookies來審視多久您才到訪本網站，瞭解您選擇查看過的網頁，並依據您的需求調整我們的服務。一般而言，我們使用cookies來估計瀏覽人數，衡量網站各部分的受歡迎程度，跟進我們行銷和促銷活動的用戶進展和進出次數，及衡量使用者的流量模式。大多數網頁瀏覽器會自動設定為接受cookies，但您通常可以更改您瀏覽器的設定以阻止自動接受。如果您選擇不使用cookies，您可能無法完全充分利用本網站。</p>
            <p>(b) 其他網站的連結</p>
            <p>為向您提供更多的資訊，本網站可能會提供其他網站的連結。當您到訪這些網站或使用其服務時，這些網站可能會收集您的個人資料。當您決定點擊任何廣告或本網站授予您到訪另一個網站的超連結時，您的個人資料可能會暴露在這些其他網站。</p>
            <p>本私隱聲明只適用於本網站。它並不適用於我們連結由其他公司或組織維持的任何網站。就這些協力廠商網站，或透過點擊在我們網站的一個超連結而接觸到的其他網站，或透過到訪其他網站把您的個人資料提供予協力廠商，我們概不負責。請確保您閱讀該等其他網站的私隱政策。</p>
          </div>
          <div className='tos_item'>
            <h4>您收到我們的行銷材料</h4>
            <p>如上所述，基於您向我們所提供的個人資料，我們可能會不時發送關於我們公司的產品、服務和類似的行銷或促銷材料和資訊給您。適當的措施已經實施，當您提供您的個人資料給我們時，可選擇“退出”收到這樣的行銷和宣傳材料。</p>
            <p>請注意，您可以隨時反對這樣使用您的個人資料。如果您不希望我們為這目的保留您的個人資料，您應該在任何我們發送的行銷電郵中透過點擊有關申請會籍或登記表框上顯示“退出”，點擊退訂連結，或通過我們網站指定您要選擇“退出”。</p>
            <p>如對上述聲明有任何疑問，請電郵至我們的客服( <a href='mailto:gfullfilmsculturalco@gmail.com'>gfullfilmsculturalco@gmail.com</a> )郵箱，與我們的職員聯絡，我們將盡快回覆。</p>
          </div>
        </div>
      </div>


      <footer>
      <ul>
          <li>
          <Link to="/faq">常見問題</Link>
              {/* <a href="/about">常見問題</a> */}
          </li>
          <li>
          <Link to="/tos">服務條款</Link>
              {/* <a href="#">服務條款</a> */}
          </li>
          <li>
          <Link to="/statement">聲明</Link>
              {/* <a href="#">聲明</a> */}
          </li>
          <li>
          <Link to="/contact">聯絡我們</Link>
              {/* <a href="/contact">聯絡我們</a> */}
          </li>
      </ul>
    </footer>
    </div>
    </>
    )
}

export default Statement;